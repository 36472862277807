













import Vue from "vue";
import userManager from "@/services/userManager";
import StatisticsPage from "@/components/StatisticsPage.vue";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import coreApiClient from "@/services/apis/coreApiClient";

export default Vue.extend({
  components: { StatisticsPage },
  data() {
    const self2 = this;
    return {
      userManager,
      importAdsCardBalanceOptions: {
        attrs: {
          small: true,
          color: "primary",
          dark: true,
          class: "ml-2 mb-4",
        },
        content: {
          text: "Import số dư thực tế",
          icon: "mdi-upload",
        },
        target: {
          tooltip: { content: { text: "Import" } },
          dialog: {
            handlers: {
              initialize() {
                this.options.content.buttons.save.states.enabled = new DataContainer(false);
              },
            },
            content: {
              toolbar: {
                title: "Import",
              },
              content: {
                type: "XForm",
                makeAttrs(attrs, dialog) {
                  attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                  attrs.xContext = dialog;
                  attrs.xData = new DataContainer({});
                  return attrs;
                },
                attrs: {
                  xOptions: {
                    content: {
                      sections: {
                        default: {
                          fields: {
                            file: {
                              type: "file",
                              attrs: {
                                label: "File",
                                required: true,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              buttons: {
                save: {
                  content: {
                    text: "Tải lên",
                  },
                  states: {},
                  on: {
                    async xClick({ self }) {
                      const dialog = self.context();
                      const form = dialog.contentContainer.value;
                      const data = form.getData();
                      self.loading.value = true;
                      const result = await coreApiClient.call(
                        "adsCardBalances",
                        "import",
                        {
                          adsAgencyId: self2.$route.params.adsAgencyId,
                        },
                        data
                      );
                      self.loading.value = false;
                      if (result) {
                        dialog.hide();
                        location.reload();
                      }
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
  },
});
